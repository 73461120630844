import { isEmpty } from '@vivino/js-web-common';
import { BasicAddress, FullAddress } from 'app/javascript/types/goApi';
import { RubyLibCartFull } from 'app/javascript/types/rubyLibApi';
import { AddressPrecisionString, Context, updateCart } from 'vivino-js/api/carts';
import { isStateBasedShipping, isZipBasedShipping } from 'vivino-js/helpers/countryHelpers';

interface CartShippingUpdateFields {
  shipping_country: string;
  shipping_state: string;
  shipping_zip: string;
}

export interface CartUpdateFields extends CartShippingUpdateFields {
  carrier_service_system_id: string;
  coupon_code: string;
  ice_pack: boolean;
  shipping_instructions: string;
  shipping_address?: FullAddress | BasicAddress;
  address_precision?: AddressPrecisionString;
  context?: Context;
  plus_plan_id?: number;
}

const CART_COUPON_UPDATE_FIELD = ['coupon_code'];

const CART_SHIPPING_UPDATE_FIELDS = [
  'shipping_country',
  'shipping_state',
  'shipping_zip',
  'shipping_address',
  'address_precision',
  /* We've seen shipping options not preserved after filling shipping and billing address
   * https://tickets.vivino.com/issues/48086
   * so passing carrier_service_system_id even if we are not updating it otherwise core api will default
   * it to standard if it is not specified all the time
   */
  'carrier_service_system_id',
  /* Appending coupon code to shipping fields to prevent
   * coupon from being deleted from carts api when zip changed
   */
  ...CART_COUPON_UPDATE_FIELD,
];

const CART_UPDATE_FIELDS = [...CART_SHIPPING_UPDATE_FIELDS, 'ice_pack', 'shipping_instructions'];

/**
 * Every time we update the cart data, we should send all other relevant existing fields otherwise
 * we risk ending up in an inconsistent state. Therefore, when we update a certain fields value,
 * we read all the rest of the fields from the current cart.
 *
 * There's a special case we need to cater for - updating shipping information only.
 * In this case, we want to only send the three shipping info fields and let the core API
 * reset the other fields if needed, with exception for carrier_service_system_id
 * even if we are not updating it otherwise core api will default it to standard even if express shipping is still
 * available at the new address.
 * Known edge-case: If the cart is in US, Arizona and the merchant
 * offers Express Shipping (which is selected on the cart) and the users changes the shipping
 * address to California where express shipping is not available, we need extra-logic to automatically
 * select standard shipping for the user. For now user has to select standard shipping manually.
 */
export const updateCartWithMandatoryFields = ({
  cart,
  body,
  shippingDataOnly = false,
}: {
  cart: RubyLibCartFull;
  body: Partial<CartUpdateFields>;
  shippingDataOnly: boolean;
}) => {
  const mandatoryUpdateFields = shippingDataOnly ? CART_SHIPPING_UPDATE_FIELDS : CART_UPDATE_FIELDS;
  const mandatoryUpdateBody = mandatoryUpdateFields.reduce((acc, fieldKey) => {
    const fieldValue = cart[fieldKey];
    if (fieldValue !== undefined) {
      acc[fieldKey] = fieldValue;
    }

    return acc;
  }, {});

  const cartUpdateBody = { ...mandatoryUpdateBody, ...body };

  return updateCart({ cartId: cart.id, body: { ...cartUpdateBody } });
};

interface UpdateCartShippingDetailsArgs {
  cart: RubyLibCartFull;
  shippingAddress: FullAddress | BasicAddress;
  addressPrecision?: AddressPrecisionString;
  context?: Context;
  plusPlanId?: number;
}

export const updateCartShippingFromShippingAddress = async ({
  cart,
  shippingAddress,
  addressPrecision,
  context,
  plusPlanId,
}: UpdateCartShippingDetailsArgs) => {
  const cartUpdateBody: Partial<CartUpdateFields> = {};

  const currentAddressShippingCountry = shippingAddress?.country?.toLowerCase();
  const cartShippingCountry = cart?.shipping_country?.toLowerCase();

  /**
   * Some merchants ship to multiple neighboring countries (i.e. Vivino FR ships to
   * France, Belgium and Monaco). The user can then update the country in the shipping
   * information. This needs to be reflected in the cart.
   */
  if (currentAddressShippingCountry && currentAddressShippingCountry !== cartShippingCountry) {
    cartUpdateBody.shipping_country = currentAddressShippingCountry;
  }

  if (isStateBasedShipping(currentAddressShippingCountry)) {
    /**
     * When the country has STATE based shipping. Just like with countries, most merchants
     * ship to multiple states within a country (i.e. in the US). When the shipping
     * state is changed, we need to update the cart.
     */
    const currentAddressStateCode = shippingAddress?.state?.toUpperCase();
    const cartShippingState = cart?.shipping_state?.toUpperCase();

    const isShippingStateChanged =
      currentAddressStateCode && currentAddressStateCode !== cartShippingState;

    /**
     * If the country has changed, always set the state as well. Otherwise only update the
     * state if it has changed from the one in the cart.
     */
    if (cartUpdateBody.shipping_country || isShippingStateChanged) {
      cartUpdateBody.shipping_state = currentAddressStateCode;
    }
  } else if (isZipBasedShipping(currentAddressShippingCountry)) {
    /**
     * When the country has ZIP based shipping. In some markets the shipping policies are
     * zip based. When the zip code changes we need to update the cart.
     */
    const currentAddressZip = shippingAddress?.zip;
    const cartShippingZip = cart?.shipping_zip;

    /**
     * If the country has changed, always set the zip as well. Otherwise only update the
     * zip if it has changed from the one in the cart.
     */
    const isShippingZipChanged = currentAddressZip && currentAddressZip !== cartShippingZip;

    if (cartUpdateBody.shipping_country || isShippingZipChanged) {
      cartUpdateBody.shipping_zip = currentAddressZip;
    }
  }

  /*
   * The cart shipping_address should be updated in almost all cases.
   * The only case it should not be updated is if the supplied shipping address AND the
   * full_shipping_address on the cart are null or undefined, in that case it is not necessary.
   * We want to ensure that the shipping address is updated in all other cases to ensure that
   * it is up to date. The shipping address is used for tax calculation purposes.
   */
  if (!isEmpty(cart?.full_shipping_address) || !isEmpty(shippingAddress)) {
    cartUpdateBody.shipping_address = shippingAddress;
  }

  if (Object.keys(cartUpdateBody).length > 0) {
    return await updateCartWithMandatoryFields({
      cart,
      body: {
        ...cartUpdateBody,
        ...(context && { context }),
        ...(addressPrecision && { address_precision: addressPrecision }),
        plus_plan_id: plusPlanId,
      },
      shippingDataOnly: true,
    });
  }
};
