import { getPaymentMethodWallet } from 'vivino-checkout/helpers/paymentOptions';
import { totalBottleCountForCart } from 'vivino-js/helpers/cart';
import { TrackingEvents } from 'vivino-js/helpers/splitHelper';

const KEYS = {
  IS_ANONYMOUS_USER: 'is_anonymous_user',
  IS_EXISTING_CUSTOMER: 'is_existing_customer',
  CURRENCY_CODE: 'currency_code',
  BOTTLE_QUANTITY: 'bottle_quantity',
  SUBTOTAL_AMOUNT: 'subtotal_amount', // pure value of wines excluding shipping/tax etc
  SHIPPING_AMOUNT: 'shipping_amount',
  TAX_AMOUNT: 'tax_amount',
  TOTAL_AMOUNT: 'total_amount',
  ICE_PACK: 'ice_pack',
  CART_ID: 'cart_id',
  MERCHANT_ID: 'merchant_id',
  MERCHANT_NAME: 'merchant_name',
  PREFILL_TYPE: 'prefill_type',
  ORDER_ID: 'order_id',
  PAYMENT_PROVIDER: 'payment_provider',
  PAYMENT_METHOD: 'payment_method',
  PAYMENT_METHOD_WALLET: 'payment_method_wallet',
  COUPON_CODE: 'coupon_code',
  COUPON_IS_VALID: 'coupon_is_valid',
  COUPON_MESSAGE: 'coupon_message',
  COUPON_SUBTOTAL: 'coupon_subtotal_amount',
  COUPON_SHIPPING: 'coupon_shipping_amount',
  COUPON_TOTAL: 'coupon_total_amount',
  ADDRESS_SUMMARY_POSITION: 'address_summary_position',
  CART_PAGE_CHECKOUT: 'cart_page_checkout',
  CARRIER_DISPLAY_NAME: 'carrier_display_name',
  SHIPPING_OPTION: 'shipping_option',
  ESTIMATED_DELIVERY_TIME_MIN_HOURS: 'estimated_delivery_time_min_hours',
  ESTIMATED_DELIVERY_TIME_MAX_HOURS: 'estimated_delivery_time_max_hours',
  SHIPPING_PRICE: 'shipping_price',
  BOTTLE_PERK: 'bottle_perk',
};

export const CHECKOUT_TRACKING_KEYS = KEYS;

export const CHECKOUT_TRACKING_EVENTS = {
  CLICK_LOGIN: 'Click Sign in',
  CLICK_CONTINUE_AS_GUEST: 'Click Continue as guest',
  SHOW: 'Show',
  ENTER_ADDRESS: 'Enter address',
  SEARCH_ADDRESS: 'Search address',
  SELECT_ADDRESS: 'Select address',
  CLICK_CONTINUE: 'Click Continue',
  CONFIRM_ADDRESS: 'Confirm address',
  ADD_COUPON: 'Add coupon',
  APPLY_COUPON: 'Apply coupon',
  REMOVE_COUPON: 'Remove coupon',
  SUBSCRIBE_OFFERS: 'Subscribe to Vivino Offers',
  CHECK_BILLING_SAME_AS_SHIPPING: 'Click same as shipping',
  SELECT_PAYMENT_OPTION: 'Click select payment options',
  PLACE_ORDER: 'Place order',
  CHANGE_SHIPPING: 'Change shipping',
  CHANGE_PAYMENT: 'Change payment',
  TERMS_OF_SALE_CHECK: 'Check terms of sale',
  TERMS_OF_SALE_UNCHECK: 'Uncheck terms of sale',
  CLICK_SUBSCRIBE: 'Click Subscribe',
  SUBSCRIBED: 'Subscribed',
  CLICK_APPLE_APP_STORE: 'Click App Store',
  CLICK_GOOGLE_PLAY: 'Click Google Play',
  CHANGE_DELIVERY_INSTRUCTIONS: 'Change delivery instructions',
  ICEPACKS_ENABLED: 'Ship with ice-packs enabled',
  ICEPACKS_DISABLED: 'Ship with ice-packs disabled',
  PAYMENT_AUTH_CANCELLED: 'Cancelled',
  PAYMENT_AUTH_TIMEOUT: 'Timeout',
  SHOW_3DS_INFO_MODAL: 'show 3ds info modal',
  CLOSE_3DS_INFO_MODAL: 'close 3ds info modal',
  DELIVERY_OPTION_SHOW: 'Delivery Option - Show',
};

export const getTrackingProps = ({
  cart = null,
  merchant = null,
  coupon = null,
  currentPayment = null,
}) => ({
  ...(cart?.id && {
    [KEYS.CART_ID]: cart.id,
    [KEYS.BOTTLE_QUANTITY]: totalBottleCountForCart(cart),
    [KEYS.SUBTOTAL_AMOUNT]: cart.subtotal_amount,
    [KEYS.SHIPPING_AMOUNT]: cart.shipping_amount,
    [KEYS.TAX_AMOUNT]: cart.tax_amount,
    [KEYS.TOTAL_AMOUNT]: cart.total_amount,
    [KEYS.ICE_PACK]: cart.ice_pack,
  }),
  ...(merchant?.id && {
    [KEYS.MERCHANT_ID]: merchant.id,
    [KEYS.MERCHANT_NAME]: merchant.name,
  }),
  ...(coupon?.code && {
    [KEYS.COUPON_CODE]: coupon.code,
    [KEYS.COUPON_MESSAGE]: coupon.message,
    [KEYS.COUPON_SUBTOTAL]: coupon.subtotal_amount,
    [KEYS.COUPON_SHIPPING]: coupon.shipping_amount,
    [KEYS.COUPON_TOTAL]: coupon.total_amount,
  }),
  ...(currentPayment?.provider && {
    [CHECKOUT_TRACKING_KEYS.PAYMENT_PROVIDER]: currentPayment.provider,
  }),
  ...(currentPayment?.method && {
    [CHECKOUT_TRACKING_KEYS.PAYMENT_METHOD]: currentPayment.method,
  }),
  ...(currentPayment?.object && {
    [CHECKOUT_TRACKING_KEYS.PAYMENT_METHOD_WALLET]: getPaymentMethodWallet(currentPayment.object),
  }),
});

export const getPurchaseOrderConfirmationTrackingParams = (purchaseOrder) => ({
  eventType: TrackingEvents.ORDER_CONFIRMATION_SCREEN_SHOW,
  props: {
    items_total_sum: purchaseOrder?.total_inc_tax,
    merchant_id: purchaseOrder?.merchant?.id,
  },
});

export const STEP_TRACKING_NAMES = {
  SHIPPING: 'Shipping Step',
  PAYMENT: 'Payment Step',
  REVIEW: 'Order summary Step',
  ORDER_CONFIRMATION: 'Order confirmation Step',
};
