import { FullAddress } from 'app/javascript/types/goApi';
import { RubyLibCartFull } from 'app/javascript/types/rubyLibApi';
import PropTypes from 'prop-types';
import React, { ReactNode, createContext, useState } from 'react';
import { AddressPrecisionString, CartResponse, Context } from 'vivino-js/api/carts';
import {
  CartUpdateFields,
  updateCartShippingFromShippingAddress,
  updateCartWithMandatoryFields,
} from 'vivino-js/helpers/cartUpdate';

interface CurrentAddress {
  shipping?: FullAddress;
  billing?: FullAddress;
}

interface CartContextValues {
  cart?: RubyLibCartFull;
  setCart: React.Dispatch<React.SetStateAction<RubyLibCartFull>>;
  setIsAddressFull: React.Dispatch<React.SetStateAction<boolean>>;
  updateCartContext: (
    body: Partial<CartUpdateFields>,
    shippingDataOnly?: boolean
  ) => Promise<CartResponse>;
  updateCartShippingDetails: (currentAddress: CurrentAddress) => Promise<CartResponse>;
  isWorking: boolean;
  setIsWorking: React.Dispatch<React.SetStateAction<boolean>>;
  setPlusPlanId: React.Dispatch<React.SetStateAction<number | null>>;
}

export const CartContext = createContext<CartContextValues | undefined>({
  cart: {},
  setCart: undefined,
  setIsAddressFull: undefined,
  updateCartContext: undefined,
  updateCartShippingDetails: undefined,
  isWorking: false,
  setIsWorking: undefined,
  setPlusPlanId: undefined,
});

interface CartContextProviderProps {
  cart: RubyLibCartFull;
  children: ReactNode;
}

export const CartContextProvider = ({ cart = {}, children }: CartContextProviderProps) => {
  const [cartState, setCartState] = useState<RubyLibCartFull>(cart);
  const [isAddressFull, setIsAddressFull] = useState<boolean>(false);
  const [plusPlanIdState, setPlusPlanIdState] = useState<number | null>(null);
  const [isWorking, setIsWorking] = useState(false);

  /** makes and API PUT request and sets the updated cart from the response on success */
  const updateCartContext = async (body: Partial<CartUpdateFields>, shippingDataOnly = false) => {
    try {
      setIsWorking(true);

      const isPlusPlanIdPresent = body.plus_plan_id !== undefined;

      const cartUpdateResponse = await updateCartWithMandatoryFields({
        cart: cartState,
        body: {
          ...body,
          // If the context has been provided with a full address, we need to make sure
          // all requests have a context checkout, to make sure if something is
          // updated(e.g. the coupon code) that the new update value also has checkout context.
          ...(isAddressFull && { context: Context.Checkout }),
          // we need to update the cart passing plus plan id if present to calculate free shipping and
          // other vivino plus benefits added in the future
          plus_plan_id: isPlusPlanIdPresent ? body.plus_plan_id : plusPlanIdState,
        },
        shippingDataOnly,
      });

      if (isPlusPlanIdPresent) {
        setPlusPlanIdState(body.plus_plan_id);
      }
      setCartState(cartUpdateResponse.cart);
      return cartUpdateResponse;
    } finally {
      setIsWorking(false);
    }
  };

  /**
   * Update the cart's shipping country and/or state or zip
   * depending if cart is in a state or zip based country
   *
   * @param {*} currentAddress the currently collected address (passed from the AddressContext)
   */
  const updateCartShippingDetails = async (currentAddress) => {
    try {
      setIsWorking(true);

      // addressPrecision and context should always be set here. When updating the shippingAddress
      // from the currentAddress, we need to specify the address precision, in case of web this
      // will only be called in checkout when a user has finished the shipping step. That address
      // in checkout should always be full which is why we set the addressPrecision as such.
      // When we set that we also need to make sure we send the context to get the precise tax.
      const cartUpdateResponse = await updateCartShippingFromShippingAddress({
        cart: cartState,
        shippingAddress: currentAddress?.shipping,
        addressPrecision: AddressPrecisionString.Full,
        context: Context.Checkout,
        plusPlanId: plusPlanIdState,
      });
      setCartState(cartUpdateResponse.cart);
      // If the request is successful we use the isAddressFull to send context checkout from then on
      setIsAddressFull(true);

      return cartUpdateResponse;
    } finally {
      setIsWorking(false);
    }
  };

  const setPlusPlanId = (plusPlanId: number | null) => {
    setPlusPlanIdState(plusPlanId);
  };

  return (
    <CartContext.Provider
      value={{
        cart: cartState,
        setCart: setCartState,
        updateCartContext,
        updateCartShippingDetails,
        isWorking,
        setIsWorking,
        setIsAddressFull,
        setPlusPlanId,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

CartContextProvider.propTypes = {
  cart: PropTypes.object,
  children: PropTypes.node,
};

export const CartContextConsumer = CartContext.Consumer;
