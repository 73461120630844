import { AddressFormat, Prefill, ValidationResult } from '@vivino/js-web-common';
import { AddressFormType } from 'app/javascript/types/address';
import { apiGet, apiPost } from 'vivino-js/apiClient';
import generateQueryUrl from 'vivino-js/generateQueryUrl';

export interface Address {
  [key: string]: string;
}

export interface AddressAutocompleteValue {
  address_id?: string;
  drilldown_id?: string;
  address_type?: string;
  display_text: string;
}

export interface AutocompleteFinder {
  service: string;
  service_logo: string;
  autocomplete_ws: string;
}

export const fetchAddress = ({
  countryCode,
  addressId,
}: {
  countryCode: string;
  addressId: string;
}): Promise<{ address: Address }> =>
  apiGet({
    url: `/api/checkout/address_formats/${countryCode.toLowerCase()}/addresses/${addressId}`,
  });

export const fetchAddressFormat = ({ countryCode }: { countryCode: string }) =>
  apiGet<{ address_format: AddressFormat }>({
    url: `/api/checkout/address_formats/${countryCode}`,
  });

export const fetchAddressUserPrefill = ({ countryCode }: { countryCode: string }) =>
  apiGet<{ address_user_prefill: Record<AddressFormType, Prefill> }>({
    url: `/api/checkout/address_formats/${countryCode}/address_user_prefill`,
  });

export const fetchAddressValidation = ({
  countryCode,
  field,
  value,
}: {
  countryCode: string;
  field: string;
  value: string;
}) => {
  const url = generateQueryUrl({
    baseUrl: `/api/checkout/address_formats/${countryCode}/address_validations/${field}`,
    params: [
      {
        name: 'value',
        value,
      },
    ],
  });
  return apiGet<{ address_validation: ValidationResult }>({ url });
};

export const fetchAddressFieldPrefill = ({
  countryCode,
  field,
  value,
}: {
  countryCode: string;
  field: string;
  value: string;
}) => {
  const url = generateQueryUrl({
    baseUrl: `/api/checkout/address_formats/${countryCode}/address_field_prefills/${field}`,
    params: [
      {
        name: 'value',
        value,
      },
    ],
  });
  return apiGet({ url });
};

export const validateAddressFormat = ({
  countryCode,
  type,
  address,
}: {
  countryCode: string;
  type: string;
  address: Address;
}) => {
  const url = generateQueryUrl({
    baseUrl: `/api/checkout/address_formats/${countryCode}/address_format_validations`,
    params: [
      {
        name: 'type',
        value: type,
      },
    ],
  });
  return apiPost({ url, body: address });
};

export function verifyShippingAddress({ address }: { address: Address }) {
  const body = {
    city: address.city,
    company: address.company,
    country: address.country,
    name: address.name,
    phone: address.phone,
    state: address.state,
    street1: address.street,
    street2: address.street2,
    zip: address.zip,
  };

  const url = '/api/address_verification';
  return apiPost({ url, body });
}
