import { FullAddress, Prefill } from '@vivino/js-web-common';
import { AddressFormType } from 'app/javascript/types/address';
import { AddressFormTypes, FieldKeys } from 'vivino-js/apiPropTypes';
import { getCurrentStateCode } from 'vivino-js/helpers/countryHelpers';

interface getCurrentAddressFromPrefillArgs {
  countryCode: string;
  addressFormTypes: AddressFormType[];
  addressUserPrefill: Record<AddressFormType, Prefill>;
  currentAddress: Record<AddressFormType, FullAddress> | {};
  ignoreShippingZipAndStateFromPrefill?: boolean;
  fallbackShippingZip?: string | number;
}

const getCurrentAddressFromPrefill = ({
  countryCode,
  addressFormTypes = [],
  addressUserPrefill,
  currentAddress = {},
  ignoreShippingZipAndStateFromPrefill = false,
  fallbackShippingZip,
}: getCurrentAddressFromPrefillArgs) => {
  countryCode = countryCode.toLowerCase();
  return addressFormTypes.reduce(
    (memo, addressFormType) => {
      const address = addressUserPrefill?.[addressFormType]?.address || {};
      const prefillAdjustments = {};

      /**
       * Even if there's no country in prefill (e.g. error fetching prefill)
       * current address needs to be initialized with country filled
       * to avoid issues with the dropdown set properly
       */
      if (!address[FieldKeys.Country]) {
        prefillAdjustments[FieldKeys.Country] = countryCode;
      }

      /**
       * Make sure to select the current state (from ship to) if nothing comes through
       * in prefill
       */
      if (!address[FieldKeys.State]) {
        prefillAdjustments[FieldKeys.State] = getCurrentStateCode();
      }

      if (addressFormType === AddressFormTypes.Shipping) {
        /**
         * If syncing the cart with the prefill has failed, ignore the zip and state
         * values and force the user the update the cart with valid shipping values.
         *
         * Otherwise, we might end up in inconsistent states. For billing, simply
         * parse the details as that has no impact on the cart.
         */
        if (ignoreShippingZipAndStateFromPrefill) {
          // set state and zip to null in shipping prefill when they need to be ignored
          prefillAdjustments[FieldKeys.State] = null;
          prefillAdjustments[FieldKeys.Zip] = null;
        } else if (!address[FieldKeys.Zip] && fallbackShippingZip) {
          /**
           * If nothing comes through in prefill, use the fallback zip that's been provided.
           * The client will get this from the cart.
           */
          prefillAdjustments[FieldKeys.Zip] = fallbackShippingZip;
        }
      }

      memo[addressFormType] = {
        ...address,
        ...prefillAdjustments,
      };
      return memo;
    },
    { ...currentAddress }
  );
};

export default getCurrentAddressFromPrefill;
