import { CarrierService, Currency } from 'app/javascript/types/goApi';

import { isZeroDecimalCurrency } from './currency';

export const EXPRESS_SHIPPING = 'Priority';
export const STANDARD_SHIPPING = 'Standard';
export const SUCCESS = 'success';
export const FAIL = 'fail';

/**
 * For stripe integration we need to send the value in the currency's subunit.
 * https://stripe.com/docs/js/appendix/payment_item_object#payment_item_object-amount
 *
 * For zero decimal currencies we need to skip this step.
 * https://stripe.com/docs/currencies#zero-decimal
 */
export const calculatePriceInCurrencySubunit = (amount: number, currency: Currency) => {
  if (isZeroDecimalCurrency(currency)) {
    return amount;
  }

  return Math.round(amount * 100);
};

export function availableShippingOptions(
  carriers: CarrierService[] = [],
  selectedCarrierId: string = undefined,
  currency: Currency = undefined
) {
  if (!carriers || !carriers.length) {
    return [];
  }

  const shippingOptions: { id?: string; label?: string; amount?: number } = {};
  const result = [];

  carriers.forEach(
    ({ carrier_service_system_id, carrier_display_name, service_display_name, cart_total }) => {
      shippingOptions[carrier_service_system_id] = {
        id: carrier_service_system_id,
        label: `${carrier_display_name} - ${service_display_name}`,
        amount: calculatePriceInCurrencySubunit(cart_total.shipping_amount, currency),
      };
    }
  );

  if (selectedCarrierId) {
    const selected = shippingOptions[selectedCarrierId];
    if (selected) {
      result.push(selected);
      delete shippingOptions[selectedCarrierId];
    }
  }

  return result.concat(Object.values(shippingOptions));
}
