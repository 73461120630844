import { useBreakpoint } from '@vivino/js-react-common-ui';
import { isEmpty } from '@vivino/js-web-common';
import { BasicVintageWithBasicWine, ExtendedVintageReview } from 'app/javascript/types/goApi';
import { RubyLibVintagePrice } from 'app/javascript/types/rubyLibApi';
import React from 'react';
import { WebApiHighlight } from 'vivino-js/api/highlights';
import Slider from 'vivino-js/utilities/Slider';
import ShowAllButton from 'vivino-ui/atoms/ShowAllButton';

import { SuperWineCardOptions } from 'vivino-ui/components/SuperWineCardSection/SuperWineCardSection';
import WineCard from 'vivino-ui/components/WineCard';

import styles from './wineBand.scss';

interface BaseWineBandProps {
  vintages: BasicVintageWithBasicWine[];
  prices: { [id: number]: RubyLibVintagePrice };
  offersTitles?: { [vintageId: number]: string };
  isShowAllVisible?: boolean;
  showAllUrl?: string;
  totalCount?: number;
  showOutOfStock?: boolean;
  showWithoutPrices?: boolean;
  isMasterWineCard?: boolean;
  useLabelShots?: boolean;
  superCardOptionsByVintageId?: { [key: string]: SuperWineCardOptions };
  highlightsByVintageId?: { [key: string]: WebApiHighlight };
  reviewsByVintageId?: { [key: string]: ExtendedVintageReview };
  headlineText?: string;
  trackingPropsByVintageId?: { [key: string]: any };
}

const BaseWineBand = ({
  prices,
  vintages,
  isShowAllVisible = false,
  showAllUrl,
  totalCount,
  showOutOfStock = true,
  showWithoutPrices = false,
  isMasterWineCard = false,
  useLabelShots = false,
  superCardOptionsByVintageId = {},
  highlightsByVintageId = {},
  reviewsByVintageId = {},
  trackingPropsByVintageId = {},
  headlineText,
}: BaseWineBandProps) => {
  const { isMobile } = useBreakpoint();

  const failedPricesCheck = !showWithoutPrices && isEmpty(prices);
  if (isEmpty(vintages) || failedPricesCheck) {
    return null;
  }

  const MobileContent = () => (
    <>
      {vintages.map((vintage) => (
        <WineCard
          key={vintage.id}
          vintage={vintage}
          price={prices?.[vintage.id]}
          showOutOfStock={showOutOfStock}
          isMasterWineCard={isMasterWineCard}
          useLabelShots={useLabelShots}
          superWineCardOptions={superCardOptionsByVintageId?.[vintage.id]}
          highlight={highlightsByVintageId?.[vintage.id]}
          review={reviewsByVintageId?.[vintage.id]}
          trackingProps={trackingPropsByVintageId?.[vintage.id]}
        />
      ))}
    </>
  );

  const DesktopContent = () => (
    <Slider pageSize={4}>
      {vintages.map((vintage) => (
        <WineCard
          key={vintage.id}
          vintage={vintage}
          price={prices?.[vintage.id]}
          showOutOfStock={showOutOfStock}
          isMasterWineCard={isMasterWineCard}
          useLabelShots={useLabelShots}
          superWineCardOptions={superCardOptionsByVintageId?.[vintage.id]}
          highlight={highlightsByVintageId?.[vintage.id]}
          review={reviewsByVintageId?.[vintage.id]}
          trackingProps={trackingPropsByVintageId?.[vintage.id]}
        />
      ))}
    </Slider>
  );

  return (
    <div className={styles.band} data-testid="baseWineBand">
      {headlineText && <div className={styles.headlineText}>{headlineText}</div>}
      {isMobile ? <MobileContent /> : <DesktopContent />}
      {isShowAllVisible && (
        <ShowAllButton url={showAllUrl} totalCount={totalCount || vintages.length} center />
      )}
    </div>
  );
};

export default BaseWineBand;
