import { getMasterWineName, vintageDisplayName } from '@vivino/js-web-common';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { CommonPropTypes, ComponentSizes } from 'vivino-js/commonPropTypes';

import styles from './wineInfoVintage.scss';

const WineInfoVintage = ({
  vintage,
  size = ComponentSizes.Base,
  isMasterWineCard = false,
  className,
}) => {
  const displayName = isMasterWineCard ? getMasterWineName(vintage) : vintageDisplayName(vintage);

  return (
    <div className={cx(styles.wineInfoVintage, styles[size], className)}>
      <div className={cx(styles.truncate)}>{vintage?.wine?.winery?.name}</div>
      <div className={cx(styles.vintage, styles.truncate)}>{displayName}</div>
    </div>
  );
};

WineInfoVintage.propTypes = {
  vintage: PropTypes.object,
  size: CommonPropTypes.componentSize,
  className: PropTypes.string,
  isMasterWineCard: PropTypes.bool,
};

export default WineInfoVintage;
